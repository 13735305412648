/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';

import Header from './Header';
import './layout.less';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import '../fonts/stack-interface.css';
import '../fonts/socicon.css';
import '../fonts/iconsmind.css';

const StyledMain = styled.main`
  .container-fluid {
    max-width: 1500px;
  }
`;

const Layout = ({ children, navBackground = 'transparent' }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type" : "LocalBusiness",
              "name" : "ALM Print",
              "image" : "https://almprint.es/img/alm_logo-dark.png",
              "telephone" : "918729809",
              "email" : "info@almprint.es",
              "sameAs" : [
              "https://www.facebook.com/almprint.es",
              "https://www.instagram.com/alm.print",
              "https://www.linkedin.com/company/almprint"
              ],
              "address" : {
              "@type" : "PostalAddress",
              "streetAddress" : "Calle Hermanos Lumiere  Polígono El Cañal, 5",
              "addressLocality" : "Arganda del Rey",
              "addressRegion" : "Madrid",
              "addressCountry" : "España",
              "postalCode" : "28500"
            },
              "openingHoursSpecification" : {
              "@type" : "OpeningHoursSpecification",
              "dayOfWeek" : [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday"
              ],
              "opens": "09:00",
              "closes": "18:00"
            },
              "url" : "https://almprint.es/"
            }
          `}
        </script>
      </Helmet>

      <Header navBackground={navBackground} />

      <StyledMain>{children}</StyledMain>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
