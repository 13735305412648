import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import logoLight from '../../static/images/alm_logo-light.png';
import logoDark from '../../static/images/alm_logo-dark.png';
import Navigation from '../Navigation';
import MenuMobile from '../MenuMobile';

const StyledNavMobile = styled.nav`
  z-index: 99;
  width: 100%;
  position: absolute;

  &.bar--transparent {
    .logo-dark {
      display: none;
    }
  }
`;

const LogosMobile = styled.div`
  text-align: center;
  margin-top: 5px;

  a {
    position: relative;
    z-index: 2;
  }
`;

const Header = ({ navBackground }) => {
  const data = useStaticQuery(graphql`
    query ContentFulAsset {
      allContentfulService {
        nodes {
          slug
          order
          title
          subTitle
        }
      }
    }
  `);

  const nodeServices = data.allContentfulService.nodes;

  const services = nodeServices.length && nodeServices.sort((a, b) => a.sort - b.sort);

  return (
    <>
      <StyledNavMobile className={`bar bar-2 visible-xs bar--${navBackground}`}>
        <MenuMobile services={services} />

        <LogosMobile>
          <a href="/">
            <img
              style={{ maxHeight: '30px', marginBottom: 0 }}
              className="logo logo-dark"
              alt="ALM logo"
              src={logoDark}
            />
            <img
              style={{ maxHeight: '30px' }}
              className="logo logo-light"
              alt="ALM logo"
              src={logoLight}
            />
          </a>
        </LogosMobile>
      </StyledNavMobile>

      <nav className={`bar bar-2 hidden-xs bar--${navBackground} bar--absolute`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-md-offset-0 col-4">
              <div className="bar__module">
                <a href="/">
                  <img
                    style={{ maxHeight: '30px', marginBottom: 0 }}
                    className="logo logo-dark"
                    alt="ALM logo"
                    src={logoDark}
                  />
                  <img
                    style={{ maxHeight: '30px' }}
                    className="logo logo-light"
                    alt="ALM logo"
                    src={logoLight}
                  />
                </a>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 text-right text-left-xs text-left-sm">
              <div className="bar__module">
                <Navigation services={services} />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
