import React, { useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';

const dropdownDisplay = keyframes`
  0%{
    opacity: 0;
    transform: scale(.98) translateY(-.6em);
  }
  
  100%{
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const StyledNavigation = styled.ul`
  > li {
    margin-right: 25px !important;

    &:last-child {
      margin-right: 0;
    }

    > a {
      opacity: 1 !important;
      font-weight: 600;
    }
  }
`;

const Dropdown = styled.ul`
  color: #ffffff;
`;

const DropdownMenu = styled.ul`
  width: 200px;
  border: 0;
  top: 100%;
  left: 0;
  z-index: 100;
  border-radius: 4px;
  padding: 20px;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15), 0 0 1px rgba(27, 31, 35, 0.2);
  animation: ${dropdownDisplay} 0.4s cubic-bezier(0.73, 0.005, 0.22, 1);
  ${({ open }) => !open && 'display: none'};

  width: 500px;
  left: -200px;

  li {
    margin-bottom: 5px;
    margin-bottom: 25px;
    display: inline-block;
    width: 50%;
    margin-right: 0 !important;

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      display: flex;
      align-items: center;
      align-content: center;

      i {
        color: #333333;
        font-size: 20px;
        margin-right: 20px;
      }
    }
  }

  p {
    color: #666;
    font-size: 12px;
    line-height: 1;
    opacity: 0.8;
    font-weight: 400;
  }

  a {
    &:hover span {
      opacity: 0.8;

      &:after {
        width: 100%;
      }
    }
  }

  span {
    color: #333;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    display: block;
    position: relative;

    &:after {
      content: '';
      width: 30%;
      height: 3px;
      position: absolute;
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
      display: block;
      left: 0;
      top: auto;
      bottom: 3px;
      z-index: -1;
      border-radius: 4px;
      transition: width 0.3s ease-in-out;
      background-color: #ff1053;
      background-color: rgb(84, 58, 183);
      background-color: rgb(0, 172, 193);
      background-color: rgb(67, 170, 139);
      background-color: rgb(249, 199, 79);
      background-color: #fec22d;
    }
  }
`;

const ServiceMenuItem = styled.div`
  a span:after {
    ${({ id }) => id === 'rotulacion-vehiculos' && 'background-color: rgb(84, 58, 183)'};
    ${({ id }) => id === 'banderas' && 'background-color: rgb(0, 172, 193)'};
    ${({ id }) => id === 'carteleria' && 'background-color: rgb(67, 170, 139)'};
    ${({ id }) => id === 'merchandising' && 'background-color: #fec22d'};
    ${({ id }) => id === 'vinilos' && 'background-color: #ff1053'};
  }
`;

const Navigation = ({ services }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <StyledNavigation className="menu-horizontal text-left">
      <li className="dropdown">
        <Dropdown
          className="dropdown__trigger"
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
        >
          <a onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <FormattedMessage id="services" />
          </a>

          <DropdownMenu open={isDropdownOpen}>
            {services.map((service, key) => (
              <li key={key}>
                <div>
                  <ServiceMenuItem id={service.slug}>
                    <Link to={`/servicio/${service.slug}`} title={service.title}>
                      <span>{service.title}</span>
                      <p>{service.subTitle}</p>
                    </Link>
                  </ServiceMenuItem>
                </div>
              </li>
            ))}
          </DropdownMenu>
        </Dropdown>
      </li>

      <li>
        <Link to={'/contacto'}>
          <FormattedMessage id="contact" />
        </Link>
      </li>

      <li>
        <Link to={'/catalogo'}>
          <FormattedMessage id="catalog" />
        </Link>
      </li>
    </StyledNavigation>
  );
};

export default Navigation;
